body {
  font-family: Lato-Regular;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

body.video_call_wrapper {
  background: #000000;
}

main {
  min-height: 513px;
}

header {
  background-color: #ffffff;
}

footer {
  background-color: #eff2f4;

  .footer-title {
    color: #4a90e2;
  }

  .copyright {
    margin-top: 20px;
    color: #000000;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #d1d1d2;
  }

  .list-group-item {
    border: none;
    background: none;
    padding: 8px 0px;

    a {
      color: #000000;
    }
  }
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.full-with-img {
  width: 100%;
  background-size: cover;
}

.oval {
  width: 128px;
  height: 128px;
  object-fit: cover;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border: solid 1px #e2e2e2;
}

.professional-profile {
  .oval {
    width: 166px;
    height: 166px;
    border: 1px solid;
  }
}

.border-color {
  border: solid 1px #e2e2e2;
}

.auth-border {
  border: solid 1px #4a4a4a;
}

.no-radius {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

.pagination {
  li {
    span {
      margin: 5px;
      text-align: center;
      color: #000000;
      border-radius: 0px;
      -moz-border-radius: 0px;
      -webkit-border-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    a {
      margin: 5px;
      text-align: center;
      color: #000000;
      border-radius: 0px;
      -moz-border-radius: 0px;
      -webkit-border-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  li:first-child {
    a,
    span {
      border-radius: 0px;
      -moz-border-radius: 0px;
      -webkit-border-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  li:last-child {
    a,
    span {
      border-radius: 0px;
      -moz-border-radius: 0px;
      -webkit-border-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  li.active {
    span {
      background-color: #004596;
    }
  }
}

.relative-area {
  position: relative;

  i {
    position: absolute;
    top: 14px;
    left: 10px;
    color: #000000;
    z-index: 2;
  }
}

.tag-pro {
  background-color: #e2e7eb;
  border: 1px solid #e2e7eb;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px 5px 10px;
}

.required {
  color: red;
}

.rounded-0 {
  border-radius: 0;
}

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #ffffff;
}

.btn-frontend-blue {
  background-color: #004596;
  color: #ffffff;
}

.btn-frontend-red {
  background-color: #cd4b39;
  color: #ffffff;
}

.btn-frontend-black {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}

.btn-register-professional {
  border: 2px solid #ffffff;
  padding: 14px 42px;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;

  margin-top: 300px;
  margin-right: 100px;
  float: right;
  display: inline-block;
}

.btn-register-professional:hover {
  color: #ffffff;
  text-decoration: none;
}

.btn-cancel {
  color: #004596;
  background-color: transparent;
  border-color: #004596;
}

.clear-border-radius {
  border-radius: 0;
}

.text-separate {
  clear: both;
}

.text-separate hr {
  width: 100%;
  color: #979797;
  margin-bottom: -10px;
}

.text-separate div {
  margin: auto;
  width: fit-content;
  background: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

ul.list-border-none {
  li {
    border: none;
    background-color: transparent;
    padding-left: 0;
  }
}

.text-primary {
  color: #007bff !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #28a745 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-info {
  color: #17a2b8 !important;
}

.inline-block {
  display: inline-block;
}

hr.separate-line {
  width: 100%;
  border-top: 1px solid #000000;
}

.custom-box-bound {
  border: solid 1px #000000;
  margin-bottom: 20px;
  overflow: hidden;
  padding: 30px;
}

/*@link https://stackoverflow.com/a/26681130/10174865 */
.circle-photo {
  /* This image is 687 wide by 1024 tall, similar to your aspect ratio */
  background-image: url("http://i.stack.imgur.com/Dj7eP.jpg");

  /* make a square container */
  width: 150px;
  height: 150px;

  /* fill the container, preserving aspect ratio, and cropping to fit */
  background-size: cover;

  /* center the image vertically and horizontally */
  background-position: top center;

  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
}

.layout-settings {
  /*font-family: Lato;*/

  .form-control {
    border-radius: 0;
    border: 1px solid #000000;
  }

  .table-borderless > tbody > tr > td,
  .table-borderless > tbody > tr > th,
  .table-borderless > tfoot > tr > td,
  .table-borderless > tfoot > tr > th,
  .table-borderless > thead > tr > td,
  .table-borderless > thead > tr > th {
    border: none;
  }

  .table-data > thead > tr > th {
    background-color: #000000;
    color: #ffffff;
  }

  .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #f3f6f7;
  }

  .setting-title {
    text-transform: uppercase;
    font-weight: bold;
  }

  .select2-container {
    .select2-selection {
      border: 1px solid #000000;
      border-radius: 0;
      height: 34px;
    }
    .datepicker {
      margin-top: 0;
    }
  }
}

.avatar-box {
  .fileinput-preview {
    border-radius: 50%;
    padding: 0;
    border: 1px solid #c4daf4;

    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;

      /** @link https://stackoverflow.com/a/35063374/10174865 */
      object-fit: cover;
    }
  }
}

.left-slide-menu {
  .list-group-item {
    text-transform: uppercase;

    a {
      color: #000000;
    }

    a:link,
    a:active {
      color: #000000;
    }

    a:hover {
      text-decoration: none;
    }

    border-radius: 0;
    border: none;
    border-top: 1px solid #000000;
  }

  .list-group-item.active {
    background-color: #000000;

    a {
      color: white;
    }

    a:link,
    a:active {
      color: white;
    }
  }

  .list-group-item:first-child {
    border-top: none;
  }

  .list-group-item:last-child {
  }
}

.sweet-alert {
  h2 {
    font-size: 20px;
  }
}

.profile-box {
  .main-header {
    text-transform: uppercase;
    font-weight: bold;
  }

  .sub-header {
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 35px;
  }

  .register-step-container {
    background-color: #000000;
    padding: 14px;

    .register-step {
      .register-step-number {
        width: 68px;
        height: 68px;
        line-height: 68px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        border-radius: 34px;
        float: left;
        border: 2px solid #ffffff;
      }

      .register-step-text {
        a {
          line-height: 68px;
          font-size: 13px;
          color: #ffffff;
          text-transform: uppercase;
          float: left;
          margin-left: 10px;
        }
      }
    }

    .register-step.active {
      .register-step-number {
        background-color: #4a90e2;
        border: 2px solid transparent;
      }

      .register-step-text {
        a {
          color: #7cb8ff;
        }
      }
    }
  }
}

.register-pro-btn {
  border: solid 2px #ffffff;
  background: transparent;
  font-family: Lato-Bold, sans-serif;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

.register-pro-btn-2 {
  border: solid 2px #ffffff;
  background: #ffffff;
  font-family: Lato-Bold, sans-serif;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

.transparent-bg {
  color: #000;
  object-fit: contain;
  opacity: 0.7;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.88) 53%,
    #ffffff
  );
  margin-right: 10px;
  padding-right: 10px;
}

.dp-flex {
  display: inline-flex;
}

.pro-bottom {
  p {
    line-height: 1.56;
  }
}

.min-h-120 {
  min-height: 120px;
}

.input-group-addon:last-child {
  border: 1px solid #000;
  background: transparent;
  position: relative;
  border-left: 0px;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

.no-border-right {
  border-right: 0px !important;
}

input[type="file"] {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border: 1px solid #000000;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: red;
}

.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  /*pointer-events: none;*/
}

.avatar-box .fileinput-preview img {
  width: 250px;
  height: 250px;
}

.no-border {
  border: none !important;
}

.message {
  .alert {
    border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
  }
}

.OT_publisher {
  position: sticky !important;
  margin: 0 auto !important;
}

.action-bar {
  margin-top: 20px;
}

.about-fernando {
  font-family: Oswald, sans-serif;
  font-size: 72px;
  font-weight: bold;
  color: #000000;
}

.about-fernando-sub-title {
  font-family: Oswald, sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #000000;
}

.about-fernando-sub-title-2 {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  margin-top: 24px;
}

.about-fernando-sub-image-2 {
  height: 50px;
}

.about_summary {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.56;
  color: #000000;
}

.about_content {
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 1.79;
  color: #000000;
}

.about_ref_text {
  font-family: Oswald, sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #000000;
}

.about_ref_button {
  width: 195px;
  height: 40px;
  background-color: #004596;
}

.about-banner-top {
  height: 300px;
  background-position: center;
}

.about-banner-bottom {
  min-height: 500px;
  height: auto;
  background-position: center;
}

.about-banner-top-text-container {
  margin-top: 150px;
}

.about-banner-bottom-text-container {
  margin-top: 100px;
}

.for-mentors-banner-top-text-container {
  margin-top: 110px;
}

.for-mentors-banner-top-sub-title {
  font-family: Lato, sans-serif;
  font-size: 16px;
  color: #000000;
}

.benefits-of-mentors-section {
  background-color: #f3f6f7;
}

.benefits-of-mentors-sub-container {
  padding-left: 34px;
  position: relative;
  line-height: 1.92;
}

.benefits-of-mentors-sub-check {
  color: #004596;
  font-weight: bold;
  position: absolute;
  left: 6px;
  top: 6px;
}

.why-learn-online-section {
  background-color: #004596;
  color: #ffffff;

  .about-fernando-sub-title {
    color: #ffffff;
  }

  .benefits-of-mentors-sub-check {
    color: #ffffff;
  }
}

#photo_container {
  #photo_box {
    position: relative;
    display: inline-block;
    border: 1px solid #000000;
    cursor: pointer;

    img#photo_preview {
      height: 192px;
    }
  }
}
.responsive-image {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
}
.wrap-form-login {
  border: solid 1px #4a4a4a;
  border-radius: 1px;
  padding: 30px;
}
.form-forgot-password {
  padding: 50px 80px;
}
 .w-100 {
   width: 100%;
 }
.form-register {
  border: solid 1px #4a4a4a;
  border-radius: 1px;
  padding: 30px;
}
.active-step-mentor-mobile {
  display: none;
}
.buttons-register-mentor-step-1 {
  float: right;
}
.register-mentor-submit-s4 {
  float: right;
}
.left-regis-step-4 {
  width: 166px;
  text-align: center;
}
.line-regis-step-4 {
  width: 166px;
  margin-left: 0px;
  border-top: 1px solid #000;
}
.become-our-mentor-bottom {
  height: auto;
}
.img-discount-home {
  height: 500px;
}
.carousel-home {
  height: 875px;
  width:100%;
}

.no-underline {
  text-decoration: none !important;
}

.mail-success-panel {
    border: solid 1px #4a4a4a; 
    border-radius: 1px; 
    padding: 30px;
}