.change-password {
    .panel-default {
        padding:50px 80px;
        .panel-heading {
            background-color: #ffffff;
            border: none;
            font-weight: bold;
            font-size: 24px;
            text-align: left;
        }        
    }
}