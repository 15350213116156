.default-widget {
  .sortable {
    .twitter-typeahead {
      width: 100%;
      .tt-menu {
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 8px 6px;
        .tt-suggestion {
          display: block;
          padding: 3px 20px;
          clear: both;
          font-weight: normal;
          line-height: 1.428571429;
          color: #333333;
          white-space: nowrap;
          &:hover{
            background-color: #1abb9c;
          }
        }
      }
    }

  }
}
