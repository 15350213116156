#page-sponsors{
  margin-top: 40px;
  .nav{
    border-bottom: 1px solid #000000;
    margin-bottom: 30px;
    li{
      padding: 0 20px 0 0;
      a{
        font-size: 24px;
        font-weight: bold;
        color: #9b9b9b;
        border: 0;
        padding: 0 0 10px 0;
        text-transform: uppercase;
      }
      &.active {
        position: relative;
        color: #000000;
        a:before{
          position: absolute;
          content: '';
          height: 4px;
          background: #000000;
          width: 100%;
          left: 0;
          bottom: 0;
        }
      }
    }
  }

  .tab-pane .row{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .item {
      height: 130px;
      margin-bottom: 30px;
      .image{
        border:1px solid #000000;
        height: 100%;
        padding:13px;
        a{
          height: 100%;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          display: flex;
        }
        .img-responsive{
          height: 100%;
        }
      }

    }
  }
}
