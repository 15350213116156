@font-face {
  font-family: 'Oswald';
  src: url('../../builds/fonts/Oswald/Oswald-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lato';
  src: url('../../builds/fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../../builds/fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Rahayu';
  src: url('../../builds/fonts/Rahayu/Rahayu.ttf') format('truetype');
}


body{
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-weight: normal;
}
.bg-color{
  background: #004596;
}
#slider-home{
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  .container{
    margin-top: 30px;
  }
  .image{
    width:100%;
    height:158px;
    background-size: cover;
  }
  h5{
    text-shadow: 0 5px 5px rgba(0, 0, 0, 0.08);
    font-family: Oswald;
    font-size: 20px;
    line-height: 1.5;
    color: #034FA0;
  }
  .active {
    h5 {
      color: #ffffff;
    }
  }
  p{
    width: 304px;
    font-family: Lato;
    font-size: 14px;
    line-height: 1.43;
    color: #000000;
  }
  #slider-home .group-btn .btn{
    width: 148px;
    height: 40px;
    background-color: #000000;
  }

  .group-btn {
    .btn{
      margin-top: 15px;
      width: 148px;
      height: 40px;
      text-align: center;
      text-transform: uppercase;
      background-color: #000000;
      border-radius: 0;
      font-weight: bold;
      font-family: Lato;
      font-size: 12px;
      color: #ffffff;
      border:1px solid #000000;
      line-height: 26px;
    }

    .btn-res{
      margin-right: 6px;
    }

    .btn-teacher{
      color: #000000;
      background-color: #ffffff;
      border:1px solid #000000;
    }
  }

  .carousel-indicators{
    position: inherit;
    display: flex;
    width: 100%;
    left: auto;
    margin: auto;
    bottom: auto;
    justify-content: center;
    padding: 20px 0;
    li{
      border:0;
      height: 16px;
      width: 16px;
      margin: 0 0 0 6px;
      background-color: #9b9b9b;
      &.active{
        background-color: #7cb8ff;
      }
    }
  }
  .carousel-control {
    &.left,&.right{
      display: none;
    }
  }
}
.wr-section{
  padding:40px 0;
}
@media screen and (min-width: 360px){
  #slider-home .image {
    height: 200px;
  }
}

@media screen and (min-width: 460px){
  #slider-home .image {
    height: 230px;
  }
}

@media screen and (min-width: 576px) {
  #slider-home .image {
    height: 280px;
  }
}

@media screen and (min-width: 768px) {
  #slider-home{
    .container{
      margin-top: 0;
    }
    .image {
      height: 381px;
    }
    h5{
      margin-top: 0;
      line-height: 1.5;
      color: #034FA0;
    }
    .active {
      h5 {
        color: #ffffff;
      }
    }
    p{
      width: 292px;
      color: #ffffff;
    }
    .item{
      .hero{
        opacity: 0;
        -webkit-transition: 2s all ease-in-out .1s;
        -moz-transition: 2s all ease-in-out .1s;
        -ms-transition: 2s all ease-in-out .1s;
        -o-transition: 2s all ease-in-out .1s;
        transition: 2s all ease-in-out .1s;
      }
      &.active{
        .hero{
          opacity: 1;
        }
      }
    }
    .container{
      height: 100%;
      top: 0;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      display: flex;

      &.left{
        flex-direction: column;
        justify-content: center;
      }
      &.center{
        justify-content: center;
        .hero{
          position: absolute;
          top:50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
      &.right{
        justify-content: flex-end;
        .hero{
          position: absolute;
          top:50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }

    }
    .group-btn {
      .btn-res{
        background-color: #ffffff;
        color: #000000;
        border:1px solid #ffffff;
      }
      .btn-teacher{
        background-color: #020202;
        color: #ffffff;
        border:1px solid #ffffff;
      }
    }
    .carousel-indicators{
      position: absolute;
      bottom:10px;
    }
  }

}

@media screen and (min-width: 992px){
  #slider-home {
    .carousel-control {
      width: 3%;

      &.left {
        position: absolute;
        left:0;

        .fa {
          position: absolute;
          right: 0;
        }
      }
      &.right {
        position: absolute;
        right:0;

        .fa {
          position: absolute;
          left: 0;
        }
      }

      .fa {
        position: absolute;
        top: 50%;
        z-index: 5;
        display: inline-block;
        /*width: 56px;
        height: 56px;*/
        margin-top: -10px;
        font-size: 56px;
      }
      .fa-arrow-right{
        right: 50%;
      }
      .fa-arrow-right{
        left: 50%;
      }
      &.left,&.right{
        display: block;
      }
    }

    .carousel-inner {
      &.item{
        -webkit-transition: 0s !important;
        -o-transition: 0s !important;
        transition: 0s !important;
      }
    }

    .image{
      height: 785px;
    }
  }
}

@media screen and (min-width: 1920px){
  #slider-home {
    .carousel-inner {
      &.item{
        height: 785px;
      }
    }
  }
}

/**
* professional
**/
.wr-professional{
  position: relative;
  &::before{
    content: 'passionate';
    font-family: 'Rahayu';
    position: absolute;
    top: 74px;
    z-index: 0;
    font-size: 14vw;
    color: #e6effa;
  }
}
.professional{
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: normal;

  .head {
    transform: rotate(-360deg);
    text-align: center;
    h2 {
      font-family: Oswald;
      font-size: 30px;
      text-align: center;
      color: #000000;
      font-weight: bold;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
    p {

      font-family: Lato;
      font-size: 14px;
      line-height: 1.43;
      text-align: center;
      color: rgba(0, 0, 0, 0.75);
      margin:0 auto 15px;
    }
  }


  form {
    margin:0;
    transform: rotate(-360deg);
  }
}
.wr-professional {
  .professional {
    .pro-view-teacher {
      height: 40px;
      width: 100%;
      text-align: center;
    }
  }
}
@media screen and (min-width: 460px) {
  .wr-professional {
    .professional {
      .pro-view-teacher {
        height: 50px;
        width: 200px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #slider-home {
    h5 {
      font-size: 20px;
    }
  }

  .professional {
    .row {
      margin: 0;
    }
    .col-sm-6 {
      width: 50%;
      float: left;
      padding: 0;
    }
  }
}

@media screen and (max-width: 460px) {
  .professional {
    .input-50{
      height:40px;
      line-height: 40px;
    }
    .col-sm-6{
      width: 100%;
      margin-bottom: 15px;
    }
    .relative-area i{
      top:9px;
    }
    .row{
      margin:0;
    }
    .select2-container--default .select2-selection--multiple{
      height: 40px !important;
      line-height: 40px !important;;
      margin-left: 0 !important;
    }
    .select2-container .select2-search--inline .select2-search__field{
      margin-top: 0 !important;
    }
    .mg-top-40{
      margin:0;
    }
  }
}

@media screen and (min-width: 576px) {
  .wr-professional{
    &::before{
      top: 49px;
    }
  }
}

@media screen and (min-width: 768px) {
  .professional {
    .head {
      h2 {
        font-size: 48px;
      }
    }
    &::before{
      top: 23px;
    }
  }
}

@media screen and (min-width: 992px) {
  .wr-professional{
    &::before{
      top: -30px;
    }
  }
}
/**
* slick-dots
 */
.slick-dots{
  li{
    button{
      border: 0;
      height: 16px;
      width: 16px;
      margin: 0 0 0 2px;
      &::before{
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        opacity: 1;
      }
    }
    &.slick-active{
      button{
        &:before{
          opacity: 1;
          color: #7cb8ff;
        }
      }
    }
  }
}
.how-to-start{
  text-align: center;
  position: relative;
  overflow: hidden;
  &::before{
    content: 'trust';
    font-family: 'Rahayu';
    font-size: 26vw;
    top: -17vw;
    position: absolute;
    color: #0657b6;
    left: 50%;
    transform: translateX(-50%);
  }
  .head {
    transform: rotate(-360deg);
    h2 {
      color: #ffffff;
      font-family: Oswald;
      font-size: 30px;
      margin-bottom: 40px;
      text-transform: uppercase;
    }
  }
  .slider-how{
    transform: rotate(-360deg);
    h3{
      font-size: 18px;
      color: #ffffff;
    }

    p{
      line-height: 1.92;
      font-size: 13px;
      color: #ffffff;
    }
    .item{
      padding:15px;
      img{
        display: inline-block;
      }
      .image{
        min-height: 114px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
      }
      &:not(:last-child){
        .image:before{
          position: absolute;
          content: '';
          width: 26vw;
          height: 2px;
          right: 0;
          border: 1px dotted #ffffff;
          opacity: 0.3;
        }
      }
    }
  }
}

@media screen and(min-width: 480px) {
  .how-to-start{
    .slider-how{
      .item{
        &:nth-child(1){
          .image:before{
            right: -30px;
          }
        }
        &:not(:last-child) {
          .image:before {
            width: 30px;
          }
        }
      }
    }
  }
}
@media screen and(min-width: 768px) {
  .how-to-start {
    &::before{
      font-size: 23vw;
      top: -15vw;
    }
    .head {
      h2 {
        font-size: 48px;
      }
    }
    .slider-how{
      .item{
        &:nth-child(1){
          .image:before{
            right: -35px;
          }
        }
        &:not(:last-child) {
          .image:before {
            width: 40px;
            right: -35px;
          }
        }
      }
    }
  }
}

@media screen and(min-width: 992px) {
  .how-to-start {
    .slider-how{
      .item{
        &:nth-child(1){
          .image:before{
            right: -45px;
          }
        }
        &:not(:last-child) {
          .image:before {
            width: 50px;
            right: -45px;
          }
        }
      }
    }
  }
}

@media screen and(min-width: 1200px) {
  .how-to-start {
    &::before{
      font-size: 16vw;
      top: -9vw;
    }
  }
}
/**
* study online
 */

.study-online {
  font-family: Lato;
  .head {
    text-align: center;
    margin-bottom: 30px;
    h2 {
      color: #000000;
      font-family: Oswald;
      font-size: 30px;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    p{
      font-size: 14px;
      line-height: 1.79;
      color: rgba(0, 0, 0, 0.75);
    }
  }
  .item{
    font-size: 13px;
    line-height: 1.92;
    color: rgba(0, 0, 0, 0.75);
    padding-left: 22px;
    position: relative;
    margin-bottom: 15px;
    &::before{
      position: absolute;
      content: '';
      background: url(../../images/icon/check.svg);
      background-size: 15px;
      width: 15px;
      height: 17px;
      left: 0;
      top: 6px;
    }
  }
  .content{
    div.group:nth-child(2){
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .pro-bottom
  .register-pro-btn {
    width: 145px;
    height: 40px;
    font-family: Lato;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: #000000;
    border:1px solid #000000;
    margin-bottom: 40px;
    line-height: 40px;
    padding:0;
  }
  .text-right{
    text-align: center;
  }

}

@media screen and(min-width: 768px) {
  .study-online {
    .content{
      div.group:nth-child(2){
        display: block;
      }
    }
    .head{
      h2{
        font-size: 48px;
      }
    }

    .content{
      display: flex;
      flex-wrap: wrap;
    }
    .item{
      width: 50%;
      float:left;
      &:nth-child(odd) {
        padding-right: 15px;
      }
      &:nth-child(even) {
        padding-left: 30px;
        &::before {
          left: 8px;
        }
      }
    }
  }
}

/**
* events
 */
picture.events{
  img{
    height: 158px;
  }
}

@media screen and (min-width: 360px){
  picture.events{
    img{
      height: 200px;
    }
  }
}

@media screen and (min-width: 460px){
  picture.events{
    img{
      height: 230px;
    }
  }
}

@media screen and (min-width: 576px) {
  picture.events{
    img{
      height: 280px;
    }
  }
}

@media screen and (min-width: 768px) {
  picture.events{
    img{
      height: 381px;
    }
  }
}

@media screen and (min-width: 992px) {
  picture.events{
    img{
      height: auto;
    }
  }
}

/**
* professional bottom
 */
.pro-bottom{
  .head {
    img {
      height: 158px;
    }
  }

  h1{
    b{
      font-family: Oswald;
    }

  }
  p{
    font-family: Lato;
  }
}

@media screen and (min-width: 360px){
  .pro-bottom{
    .head {
      img {
        height: 200px;
      }
    }
  }
}

@media screen and (min-width: 460px){
  .pro-bottom{
    .head {
      img {
        height: 230px;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .pro-bottom{
    .head {
      img {
        height: 280px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .pro-bottom {
    position: relative;
    overflow: hidden;
    .head {
      img {
        height: 100%;
        width: auto;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    h1 {
      width: 100%;
      float:left;
      text-align: center;
      padding: 0;
      b {
        display: none;
      }
      &.font-size-64{
        font-size: 53px;
      }
    }
    .container{
      padding:80px 15px;
      .description{
        max-width: 582px;
      }
    }
  }
}

@media screen and (min-width: 992px){
  .pro-bottom {
    h1 {
      &.font-size-64 {
        font-size: 64px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .pro-bottom {
    .row{
      margin:0;
    }
    h1{
      text-align: center;
      padding:0;
      .pull-right{
        display: none;
      }
      b{
        font-family: Oswald;
        font-size: 30px;
        font-weight: 500;
        letter-spacing: -1.7px;
        color: #000000;
      }

    }
    p{
      text-align: center;
      padding:0;
      font-family: Lato;
      font-size: 13px;
      line-height: 1.54;
      color: #000000;
    }
  }
}
/**read more button**/
.wr-read-more{
  text-align: center;
  margin-top: 30px;
  .read-more{
    width: 145px;
    height: 40px;
    border:1px solid #000000;
    color: #000000;
    background: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
  }
}

@media screen and (max-width: 651px) {
  #slider-home {
    h5 {
      font-size: 18px;
    }

    .video-overlay {
      .eyecatchers {
        padding: 20px 10px 20px 10px;

        .img-icon {
          width: 32px;
          margin-bottom: 0px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 595px) {
  #slider-home {
    h5 {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 535px) {
  #slider-home {
    h5 {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 480px) {
  #slider-home {
    h5 {
      font-size: 13px;
    }
  }

  .video-overlay {
    .eyecatchers {
      .img-icon {
        width: 24px;
      }
    }
  }
}

@media screen and (max-width: 446px) {
  #slider-home {
    h5 {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 417px) {
  #slider-home {
    h5 {
      font-size: 11px;
    }
  }
}

@media screen and (max-width: 388px) {
  #slider-home {
    h5 {
      font-size: 9px;
    }
  }
}

@media screen and (max-width: 330px) {
  #slider-home {
    h5 {
      font-size: 8px;
    }
  }
}