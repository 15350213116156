.video_call_wrapper {
    #slider-subscribers {    
        #subscribers {
            height: 800px;
            
            .OT_subscriber {
                margin-bottom: 3%;
            }
        }
    }
}

.full-width {
    width: 100%;
}

.layout-carousel-home {
    position: relative;

    .video-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        width: 100%;

        .section-inner {
            position: absolute;
            bottom: 20px;
        }

        .eyecatchers {
            background-color: rgba(255, 255, 255, 0.5);
            vertical-align: top;
            text-align: center;
            height: 100%;
            padding: 30px 10px 30px 10px;

            h5 {
                color: #034FA0 !important;
            }

            .img-icon {
                width: 3em;
            }

            &.active {
                border-left: 1px solid rgba(50,95,155, 0.5);
                border-right: 1px solid rgba(50,95,155, 0.5);
            }

            &:hover {
                text-decoration: none;
                background-color: rgba(50,95,155, 0.5);

                h5 {
                    color: #ffffff !important;
                }

                .img-icon {
                    display: none !important;

                    &.img-icon-white {
                        display: inline-block !important;
                    }
                }
            }

            &:focus {
                text-decoration: none;
            }
        }
    }
}