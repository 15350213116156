.navbar {
    margin-bottom: 0px;
}

.navbar-inverse {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #000000;
    font-size: 12px;

    .navbar-brand {
        height: auto;        
    }

    .navbar-nav>li>a {
        color: #000000;
        font-family: "Lato-Regular";
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        line-height: 60px;
        border-bottom: 4px solid transparent;
        text-transform: uppercase;
        .bg-login-btn {
            background: #FFFFFF;
            color: #5A5F60;
            padding: 10px 20px;
            border-radius: 5px;
            font-weight: bold;
        }
    }

    .navbar-nav>.active>a,
    .navbar-nav>.active>a:focus, 
    .navbar-nav>.active>a:hover,
    .navbar-nav>li>a:hover,
    .navbar-nav>li>a:focus, 
    .navbar-nav>li>a:hover {
        color: #004596;
        background-color: #ffffff;        
        line-height: 60px;
        border-bottom: 4px solid #004596;
    }
}

.nav.horizontal-nav > li {
    display: inline-block;
}

#profile-tabs {
    .nav.horizontal-nav > li > a {
        color: #000000;
        border-bottom: 2px solid transparent;
    }

    .nav.horizontal-nav > li > a:hover {
        border-bottom: 2px solid #000000;
    }

    .nav.horizontal-nav > li > a.active {
        border-bottom: 2px solid #000000;
    }

}