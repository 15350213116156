.contact{
  &>h2{
    font-family: Oswald;
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .item{
    &>label{
      position: relative;
      text-transform: uppercase;
      padding-left: 16px;
      &:before{
        content: '';
        position: absolute;
        left: 0;
        height: 100%;
        width: 4px;
        background-color: rgba(0, 0, 0, 0.75);
      }
    }
    label{
      font-family: Lato;
      font-size: 13px;
      font-weight: bold;
      color: #000000;
    }
    &>p{
      font-family: Lato;
      font-size: 13px;
      line-height: 1.92;
      color: rgba(0, 0, 0, 0.75)
    }
    .form-control{
      border-color: #000000;
      border-radius: 0;
    }
    .btn{
      text-transform: uppercase;
      width: 166px;
      height: 32px;
      background-color: #004596;
      font-family: Lato;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      border-radius: 0;
      border:0;
    }

    textarea{
      resize: none;
    }
  }
}
#map {
  margin-top: 61px;
  font-size: 0;
  margin-left: -15px;
  margin-right: -15px;
}