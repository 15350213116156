.flex-wrap{
  display: flex;
  flex-wrap: wrap;
}
.the-post {
  .banner {
    margin-bottom: 53px;
    margin-top: 12px;
    overflow: hidden;
    &>h2{
      font-family: Oswald;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      color: #000000;
      text-transform: uppercase;
    }
    &>p{
      font-family: Lato;
      font-size: 14px;
      line-height: 1.43;
      text-align: center;
      color: #000000;
      margin:0;
    }
  }

  .item{
    max-width: 390px;
    margin: 0 auto;
    float:none;
    .img-responsive{
      margin-bottom: 24px;
	  max-height: 240px;
    }
    .info{
      margin-bottom: 8px;
    }
    .date{
      font-family: Montserrat;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
    }
    .author{
      font-family: Montserrat;
      font-size: 12px;
      font-weight: bold;
      color: #004596;
    }
  }

  .post-excerpt, .post-excerpt a{
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    display: block;
    margin-bottom: 16px;
  }
  .post-content{
    font-family: Montserrat;
    font-size: 13px;
    line-height: 1.54;
    color: rgba(0, 0, 0, 0.75);
    margin-bottom: 32px;
  }
}

  .detail-post{
    margin-top: 40px;
    overflow: hidden;
    margin-bottom: 40px;
    &>h2{
      font-family: Oswald;
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 16px;
      text-transform: uppercase;
    }
    &>header{
      margin-bottom: 22px;
      .info{
        margin-bottom: 16px;
      }
    }
    .post-excerpt{
      font-size: 14px;
      line-height: 1.79;
    }
    .content{
      font-size: 14px;
      line-height: 1.79;
      color: rgba(0, 0, 0, 0.75);
    }
  }

  .the-tag{
    margin-bottom: 21px;
    border-top: 1px solid #000000;
    padding-top: 8px;
    .item{
      width: 50px;
      height: 24px;
      font-family: Lato;
      font-size: 12px;
      color: #000000;
      display: inline-block;
      background: #e2e7eb;
      text-align: center;
      line-height: 24px;
      margin-bottom: 8px;
      &:not(:last-child){
        margin-right: 8px;
      }
    }
  }

  /*relate*/
  .post-relate{
    margin-bottom: 20px;
    h2 {
      font-family: Oswald;
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 26px;
    }
    .item{
      display: flex;
      .img-responsive{
        width: 98px;
        float:left;
      }
      .content{
        float:left;
        padding-left:16px;
      }
    }
    article .item{
      margin-bottom: 24px;
      overflow: hidden;
    }
    h3{
      margin-top:0;
      margin-bottom:8px;
      font-size: 14px;
      a{
        font-family: Lato;
        font-weight: bold;
        color: #000000;

      }
    }
    .author{
      font-family: Montserrat;
      font-size: 12px;
      font-weight: bold;
      color: #004596;
    }
  }



