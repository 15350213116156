@font-face {
    font-family: 'Lato-Black';
    src: url(../../plugins/lato/Lato-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-BlackItalic';
    src: url(../../plugins/lato/Lato-BlackItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-Bold';
    src: url(../../plugins/lato/Lato-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-BoldItalic';
    src: url(../../plugins/lato/Lato-BoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-Hairline';
    src: url(../../plugins/lato/Lato-Hairline.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-HairlineItalic';
    src: url(../../plugins/lato/Lato-HairlineItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-Italic';
    src: url(../../plugins/lato/Lato-Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-Light';
    src: url(../../plugins/lato/Lato-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-LightItalic';
    src: url(../../plugins/lato/Lato-LightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url(../../plugins/lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Material Icons';
    src: url(../../plugins/material-icon/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.btn-primary {
    color: #fff;
    background-color: #004596;
    border-color: #004596;
}

.btn-primary.active.focus, 
.btn-primary.active:focus, 
.btn-primary.active:hover, 
.btn-primary:active.focus, 
.btn-primary:active:focus,
.btn-primary:active:hover, 
.open>.dropdown-toggle.btn-primary.focus, 
.open>.dropdown-toggle.btn-primary:focus, 
.open>.dropdown-toggle.btn-primary:hover {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}

#myNavbar {
    .navbar-nav {
        float: none;
        margin: 0 auto;
        width: max-content;                
    }
    
    .navbar-nav>li {
        float: none;
        display: inline-block;
    }
}


.navbar-inverse {
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

.black-version.navbar-inverse {
    background-color: #000000;
    border-color: #000000;
    color: #ffffff;
    font-size: 12px;
}

.black-version.navbar-inverse .navbar-nav > li > a {
    color: #ffffff;
}

.black-version.navbar-inverse .navbar-nav > .active > a,
.black-version.navbar-inverse .navbar-nav > .active > a:focus, 
.black-version.navbar-inverse .navbar-nav > .active > a:hover, 
.black-version.navbar-inverse .navbar-nav > li > a:hover, 
.black-version.navbar-inverse .navbar-nav > li > a:focus, 
.black-version.navbar-inverse .navbar-nav > li > a:hover {
    color: #4A90E2;
    background-color: #000000;
    line-height: 60px;
    border-bottom: 4px solid #ffffff;
}

.navbar-nav>li>.dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    margin-left: -1px;
    margin-top: -22px;
    
    li {
        margin-top: 10px;
    }
    
    li:last-child {
        margin-bottom: 10px;
    }
}

.navbar-inverse .navbar-nav>.open>a, 
.navbar-inverse .navbar-nav>.open>a:focus,
.navbar-inverse .navbar-nav>.open>a:hover {
    color: #4A90E2;
    background-color: transparent;
}

.black-version.navbar-inverse .navbar-nav>.open>a, 
.black-version.navbar-inverse .navbar-nav>.open>a:focus,
.black-version.navbar-inverse .navbar-nav>.open>a:hover {
    color: #4A90E2;
}

.black-version.navbar-inverse .navbar-nav > .active > a {
    color: #ffffff;
}

.navbar-inverse .navbar-nav > li > a:focus {
    color: #000000;
    border-bottom: transparent;
}

.black-version.navbar-inverse .navbar-nav > li > a:focus {
    color: #ffffff;
    border-bottom: transparent;
}