//responsive for mobile
@media (max-width: 767px) {
  //responsive for frontend.users.show_fields
  .layout-show-user {
    .wrap-information {
      text-align: center;
    }
    .avatar-box .fileinput-preview img {
      width: 150px !important;
      height: 150px !important;
    }
  }
  .w-100-responsive {
    width: 100%;
  }
  .margin-bottom-8 {
    margin-bottom: 8px;
  }
  //responsive for frontend.booking.my_bookings
  .wrap-box-bookings {
    border: 1px solid #000;
    padding: 15px;
  }
  //responsive for frontend.about.index
  .layout-about {
    .about-fernando {
      font-size: 36px;
    }
    .about_content {
      overflow: hidden;
    }
  }
  .im-mentor {
    background-color: #fee101;
    padding: 20px 43px 20px 8px;
    text-transform: uppercase;
    text-align: start;
    p {
      font-family: Oswald;
      font-size: 20px;
      font-weight: bold;
      color: #000000;
    }
  }
  .im-student {
    background-color: #ffc838;
    padding: 20px 8px 20px 43px;
    text-transform: uppercase;
    text-align: end;
    p {
      font-family: Oswald;
      font-size: 20px;
      font-weight: bold;
      color: #000000;
    }
  }
  .icon-about {
    font-size: 20px;
  }
  //responsive for frontend.about.mentor
  .slider-for-mentors {
    .slick-dots {
      li {
        button {
          border: 0;
          height: 16px;
          width: 16px;
          background-color: #9b9b9b;
          border-radius: 16px;
        }
        button::before {
          content: "";
        }
      }
      .slick-active button {
        background-color: #7cb8ff;
      }
    }
    .slick-slide img {
      margin: 0 auto;
    }
  }
  .for-mentors-mobile {
    font-size: 30px;
    font-weight: bold;
  }
  .for-mentors-banner-top-sub-title {
    font-size: 14px !important;
  }
  .about_content {
    margin-top: 20px;
  }
  .mobile-content-mentors {
    margin-top: 0px !important;
  }
  .about-fernando-sub-title {
    font-size: 24px !important;
  }
  //responsive for frontend.professionals.index
  .layout-professional {
    .oval {
      width: 40px;
      height: 40px;
    }
  }
  //responsive for frontend.about.learners
  .layout-learners {
    .about_ref_text {
      text-align: center;
      font-size: 30px;
    }
    .about-banner-bottom {
      height: 200px;
    }
    .responsive-image-learner {
      background-position: 100% 100%;
    }
    .about-fernando {
      font-size: 30px;
    }
  }
  //responsive for frontend.auth.login
  .layout-login {
    .wrap-form-login {
      padding: 0px;
      border: none;
      .text-separate div {
        background-color: #f3f6f7;
      }
    }
    .panel {
      background-color: #f3f6f7;
    }
    .panel-default .panel-heading {
      background-color: #f3f6f7;
    }
  }
  //responsive for frontend.auth.forgot_email
  .layout-forgot-password {
    .form-forgot-password {
      padding: 8px;
    }
    .text-forgot-pass {
      text-align: center;
    }
    .auth-border {
      border: none;
    }
    .panel {
      background-color: #f3f6f7;
    }
    .panel-default .panel-heading {
      background-color: #f3f6f7;
    }
  }
  //responsive layouts.partials.frontend_header_black
  #myNavbar {
    .navbar-nav {
      margin: 0;
      width: 100%;
      li {
        display: block !important;
        a {
          text-align: start;
          border: none !important;
          line-height: 20px;
          padding-left: 0px;
        }
      }
      .active {
        a {
          color: #004596 !important;
          background-color: transparent;
        }
      }
    }
  }
  .layout-header {
    .navbar-toggle .icon-bar {
      background-color: #000 !important;
    }
  }
  .mg-bottom-8-responsive {
    margin-bottom: 8px;
  }

  //responsive frontend.auth.register
  .layout-register {
    .panel {
      background-color: #f3f6f7;
    }
    .panel-default .panel-heading {
      background-color: #f3f6f7;
    }
    .form-register {
      padding: 20px 0px;
      border: none;
    }
  }

  //responsive frontend.auth.password
  .for-responsive-change-pass {
    .change-password .panel-heading {
      text-align: center;
    }
  }
  //responsive frontend.professional.create
  .layout-register-mentor {
    padding: 0px !important;
    .unactive-mobile {
      display: none;
    }
    .active-step-mentor-mobile {
      display: inline-block;
    }
    .register-step {
      padding-left: 0px;
    }
    .profile-box .main-header {
      font-size: 22px;
    }
    .buttons-register-mentor-step-1 {
      float: none;
    }
    .register-mentor-submit-s4 {
      float: none;
    }
    .register-step-4 {
      .oval {
        width: 262px;
        height: 262px;
      }
      .left-regis-step-4 {
        width: 100%;
      }
      .line-regis-step-4 {
        width: 100%;
      }
    }
  }
  .text-center-responsive {
    text-align: center;
  }
  //responsive layout.frontend_profile
  .layout-settings {
    .nav.horizontal-nav {
      .nav-item {
        display: block;
      }
    }
  }
  //responsive frontend.professional.partial.become_to_mentor
  .layout-become-our-mentor-bottom {
    .responsive-image-bom {
      h1 b, p {
        color: #ffffff;
      }

      /*background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 160px;*/
    }
    .register-now-mentor {
      border: 1px solid #000;
      border-radius: 0px;
      font-size: 13px;
      font-weight: bold;
    }
  }
  //responsive frontend.professionals.show_fields
  .layout-mentor-profile.custom-box-bound {
    padding: 30px 0px;
    border: none;
    .wrap-information {
      text-align: center;
    }
    .avatar-box .fileinput-preview img {
      width: 262px;
      height: 262px;
    }
  }
  //responsive frontend.user.edit
  .layout-edit-users {
    .avatar-box .fileinput-preview img {
      width: 150px;
      height: 150px;
    }
  }
  //responsive for frontend.professional.profile
  .layout-professional-profile {
    .content-overview {
      overflow: hidden;
    }
  }
  //responsive frontend.partials.carousel_slide
  .layout-carousel-home {
    .carousel-home {
      height: 158px;
    }
  }
  
    //responsive for frontend.professional.profile
  .layout-professional-profile {
    .name-professional {
      font-size: 30px;
      color: #000;
    }
    .detail-intro {
      margin-top: 10px;
    }
  }

}

//responsive for desktop (menu is to width)
@media (min-width: 991px) and (max-width: 1280px) {
  #myNavbar {
    .navbar-nav.nav > li > a {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

//responsive for tablet
@media (min-width: 768px) and (max-width: 991px) {
  .mg-bottom-8-tablet {
    margin-bottom: 8px;
  }
  //responsive for frontend.about.index
  .layout-about {
    .about-fernando {
      font-size: 36px;
    }
    .about-banner-top-text-container {
      margin-top: 65px;
    }
    .about-banner-bottom-text-container {
      margin-top: 36px !important;
    }
  }

  //responsive for frontend.about.mentor
  .about-banner-top {
    height: 125px !important;
  }
  .about-banner-top-second-pages {
    height: 300px !important;
  }
  .for-mentors-banner-top-text-container {
    margin-top: 0px !important;
  }
  .text-banner-mentors {
    font-size: 30px !important;
  }
  .about-fernando-sub-title {
    font-size: 24px !important;
  }
  .slider-for-mentors {
    .slick-dots {
      li {
        button {
          border: 0;
          height: 16px;
          width: 16px;
          background-color: #9b9b9b;
          border-radius: 16px;
        }
        button::before {
          content: "";
        }
      }
      .slick-active button {
        background-color: #7cb8ff;
      }
    }
    .slick-slide img {
      margin: 0 auto;
    }
  }
  //responsive for frontend.auth.forgot_email
  .layout-forgot-password {
    .form-forgot-password {
      padding: 24px 30px;
    }
    .text-forgot-pass {
      text-align: center;
    }
    .auth-border {
      border: none;
    }
  }
  //responsive frontend.professional.create
  .profile-box {
    .layout-register-mentor {
      padding: 0px !important;
      .register-step {
        padding-left: 0px;
      }
      .profile-box .main-header {
        font-size: 22px;
      }
      .register-step-container .register-step .register-step-text a {
        font-size: 12px;
      }
    }
  }
  .pd-right-0-responsive {
    padding-right: 0px;
  }

  //responsive layouts.partials.frontend_header_black
  #myNavbar {
    .navbar-nav {
      float: left;
    }
  }
  //responsive for frontend.about.learners
  .layout-learners {
    .about-banner-bottom {
      min-height: 200px;
      height: auto;
      background-position: 100% 100%;
    }
    .pro-bottom .container {
      padding: 25px 15px;
    }
    .about-banner-bottom-text-container {
      margin-top: 0px;
    }
    .about_ref_text {
      font-size: 30px;
      margin-top: 0px;
    }
    .pd-top-30 {
      padding-top: 0px;
    }
  }
  //responsive frontend.professional.partial.become_to_mentor
  .layout-become-our-mentor-bottom {
    .pro-bottom {
      h1.text-become-our-mentors {
        font-size: 30px;
      }
      .container {
        padding: 10px 15px;
        .description {
          font-size: 13px;
        }
      }
    }
  }
  //responsive for frontend.users.show_fields
  .layout-show-user {
    .avatar-box .fileinput-preview img {
      width: 190px;
      height: 190px;
    }
  }
  //responsive for frontend.professional.profile
  .layout-professional-profile {
    .name-professional {
      font-size: 30px;
      color: #000;
      font-weight: bold;
    }
  }
  //responsive frontend.partials.carousel_slide
  .layout-carousel-home {
    .carousel-home {
      height: 381px;
    }
  }
}
//responsive for mobile and tablet
@media (max-width: 991px) {
  //responsive frontend.auth.password
  .for-responsive-change-pass {
    .change-password .panel-default {
      padding: 32px 24px !important;
    }
  }
  //responsive frontend.professional.profile
  .layout-professional-profile {
    .professional-profile {
      margin-top: 0px;
    }
    .professional .row {
      margin-right: -15px;
      margin-left: -15px;
    }
    .text-starting-form {
      color: #4a4a4a;
    }
  }
}

@media screen and (min-width: 22.5em){
  #page-sponsors .item{
    height: 165px;

  }
  #page-sponsors {
    .nav {
      li {
        padding: 0 48px 0 0;
      }
    }
  }
}
@media screen and (min-width: 48em) {
  /*sponsor*/
  #page-sponsors {
    .nav{
      margin-bottom: 40px;
    }
    .tab-content{
      margin-bottom: 10px;
    }
    .nav>li>a:focus, .nav>li>a:hover{
      color: #000000;
      background-color: transparent;
    }
  }
  /*contact*/
  #page-contact{
    margin-top: 51px;
  }
  #map {
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .contact{
    margin-bottom: 30px;
  }
  .the-tag{
    padding-top: 16px;
    .item {
      width: 66px;
      height: 32px;
      line-height: 32px;
      &:not(:last-child){
        margin-bottom: 16px;
      }
    }
  }

  .the-post {
    .banner{
      margin-bottom: 31px;
      margin-top:0px;
      background-image: url('/images/shutterstock.jpg');
      background-size: cover;
      &>h2{
        margin-top: 38px;
        color: #ffffff;
      }
      &>p{
        color: #ffffff;
        margin-bottom: 20px;
      }
    }
    .item{
      max-width: 100%;
      float:left;
    }
  }
}
@media screen and (min-width: 62em) {
  .contact{
    width: 50%;
    float:right;
  }
  .detail-post{
    margin-top: 20px;
  }
  .sidebar-post-left {
    width: 678px;
    float:left;
    padding-right:50px;
  }
  .sidebar-post-right{
    width: 262px;
    float:left;
    margin-top: 20px;
  }
  .sidebar-post-right {
    .row{
      margin:0;
      &>article{
        padding:0;
      }
    }
  }

  .the-post .banner{
    &>h2{
      margin-top: 127px;
      font-size: 48px;
    }
    &>p{
      font-size: 16px;
      margin-bottom: 69px;
    }
  }
}
@media screen and (min-width: 75em) {
  .sidebar-post-left {
    width: 878px;
    padding-right:128px;
  }

  #page-sponsors {
    .nav{
      margin-bottom: 85px;
    }
    .tab-content{
      margin-bottom: 85px;
    }
  }
}
