.pro-title {
    font-family: Lato-Bold;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.pro-content {
    font-family: Lato-Regular;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: normal;
    color: #4a4a4a;
}

.pro-price {
    font-family: Lato-Regular;
    font-size: 20px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
}

.pro-view-teacher {
    font-family: Lato-Bold;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
}

.select2-container--default {
    .select2-selection--multiple {
        height: 34px;
        display: block;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #000000;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        border-radius: 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px;
    }
}

.professional {
    padding-bottom: 30px;        

    .select2-container {
        width: 100% !important;
    }

    .select2-container--default {
        .select2-selection--multiple {
            height: 50px;
            line-height: 38px;
            padding-left: 45px;
            margin-left: -1px;
        }
    }

    .select2-container--default.select2-container--focus {
        .select2-selection--multiple {
            border-color: #66afe9;
            outline: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }
    }

    .select2-container--default {
        .select2-selection--multiple {
            .select2-selection__rendered {
                box-sizing: border-box;
                list-style: none;
                margin: 0;
                padding: 0 5px;
                width: 100%; 
                text-align: center;
            }

            .select2-selection__choice {
                background-color: #e2e7eb;
                border: 1px solid #e2e7eb;
                border-radius: 0px;
                -moz-border-radius: 0px;
                -webkit-border-radius: 0px;
                cursor: default;
                float: left;
                margin-right: 4px;
                margin-top: 4px;
                padding: 0px 8px 0px 8px;
            }

            .select2-selection__choice__remove {
                color: #999;
                cursor: pointer;
                display: inline-block;
                font-weight: bold;
                margin-right: 0px;
                float: right;
                margin-left: 5px;
            }
        }   

        .select2-search--inline {
            .select2-search__field {
                background: transparent;
                border: none;
                outline: 0;
                box-shadow: none;
                -webkit-appearance: textfield;
                position: relative;
            }
        }
    }
}

.professional-profile {
    margin-top: -131px;
}

.professional-profile.register-step-4 {
    margin-top: 0px;
}

.input-50 {
    height: 50px;
    line-height: 38px;
    border-color: #000000;
}

.pro-modal-title {
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #004596;
}

.detail-intro {
    margin-top: 80px;
    color: #000000;
}